import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "@emotion/styled"
import Bootstrap from "../components/layout/bootstrap"
import Footer from "../components/layout/footer"
import Link from "../components/link"
import { Deck, Card, CardTitle, CardBody } from "../components/card"

const Jumbotron = styled.header`
  width: 100%;
  height: 30vh;
  background-color: ${props => props.theme.color.primary};
  ${props => props.theme.typography.headline1};
  text-transform: upper;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: center;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  box-shadow: 0px 0px 8px 0px black;
`

const BriefingCard = ({ group, title, description }) => (
  <Card>
    <CardTitle divider> {title} </CardTitle>
    <CardBody>{description}</CardBody>
    <Link main to={`/${group}`}>
      Learn more...
    </Link>
  </Card>
)

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      {
        groups: allGroupsCsv {
          nodes {
            title
            group
            description
          }
        }
      }
    `}
    render={({ groups }) => (
      <Bootstrap>
        <Jumbotron> AIP gate </Jumbotron>
        <Deck>{groups.nodes.map(BriefingCard)}</Deck>
        <Footer />
      </Bootstrap>
    )}
  />
)

export default IndexPage
